const debugKey = 'bui_debug';
import { useMicroAppStore } from '@/hooks/useMicroAppStore';
import { OpenParams, useQiankunModalStore } from '@/hooks/useQiankunModalStore';
import '@bui/eimos-sdk/dist/permission';
import { i18n } from '@bui/i18n';
import { buiService } from '@bui/req';
import { fastGPT, FastGPTConfig } from './fastGPT';

export const eimos = {
  _userInfoDeferred: buiService.userInfo,
  report_info: {},
  debug: {
    open() {
      localStorage.setItem(debugKey, 'true');
    },
    close() {
      localStorage.removeItem(debugKey);
    },
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.eimos = window.eimos || {};
Object.assign(window.eimos as any, eimos);

export function registerEimos<T extends (...args: any[]) => any>(methodName: string, callback: T) {
  window.eimos = window.eimos || {};
  const [namespace, method] = methodName.split('.').filter((item) => !!item);
  (window.eimos as any)[namespace] = (window.eimos as any)[namespace] || {};
  (window.eimos as any)[namespace][method] = (window.eimos as any)[namespace][method] || callback;
}

(window.eimos as any).__pageIns = (window.eimos as any)?.__pageIns || new Map<string, any>();

registerEimos('utils.i18n', (key: string, defaultValue: string) => {
  return i18n.t(key, {
    defaultValue,
  });
});

registerEimos('utils.addPageIns', (ins: any) => {
  const activePage = useMicroAppStore.getState().getActivePage();
  if (activePage) {
    (window.eimos as any).__pageIns.set(activePage.id, ins);
  }
});

registerEimos('utils.deletePageIns', (pageId: string) => {
  const getPageId = () => {
    if (pageId) {
      return pageId;
    }
    const activePage = useMicroAppStore.getState().getActivePage();
    if (activePage) {
      return activePage.id;
    }
  };
  const id = getPageId();
  (window.eimos as any).__pageIns.delete(id);
});

registerEimos('utils.getActivePageIns', () => {
  const activePage = useMicroAppStore.getState().getActivePage();
  if (activePage) {
    return (window.eimos as any).__pageIns.get(activePage.id);
  }
});

registerEimos('utils.open', (params: OpenParams, callback: (...args: any[]) => void) => {
  //  如果打开类型为'tab'，则使用微前端应用的状态管理中的open方法打开新页面
  if (params.actionType === 'tab') {
    const open = useMicroAppStore.getState().open;
    open && open(params.url, params.title);
    return;
  }
  useQiankunModalStore.getState().open(params, callback);
});

registerEimos('utils.fastGPT', async (config: FastGPTConfig & { question: string }) => {
  return await fastGPT.question(config);
});

// 用来处理ai助手setValues LCP高亮样式

export const changeLcpStyle = {
  clear: () => {
    console.log('暂无清空setForm更改样式的需求');
  },
  setClear: (callback: () => void) => {
    changeLcpStyle.clear = callback;
  },
  reset: () => {
    changeLcpStyle.clear = () => {
      console.log('暂无清空setForm更改样式的需求');
    };
  },
};
