import { enableMapSet } from 'immer';
import { appName, isQiankun } from './config';
enableMapSet();
console.log('主 js 最先执行时 public-path 中');
console.timeLog('appInit');
if (isQiankun) {
  if (process.env.NODE_ENV === 'development') {
    __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__!;
  } else {
    __webpack_public_path__ = `${origin}/${appName}/`;
  }
  console.log('__webpack_public_path__', __webpack_public_path__);
}
