
      try {
        window.BUI_BUILD_VERSION = window.BUI_BUILD_VERSION || {};
        window.BUI_BUILD_VERSION["eimos"] = window.BUI_BUILD_VERSION["eimos"] || {};
        window.BUI_BUILD_VERSION["eimos"].commitId = "099a445";
        window.BUI_BUILD_VERSION["eimos"].branchName = "HEAD";
        window.BUI_BUILD_VERSION["eimos"].version = "PDT_20241220";
        window.BUI_BUILD_VERSION["eimos"].date = "2024-12-24 20:2:25";
      } catch (e) {
        console.error('BuiBuildVersion error');
        console.error(e);
      }
    