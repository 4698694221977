import { useAsyncEffect } from 'ahooks';
import { useState } from 'react';
import { usePreferenceStore } from './usePreferenceConfig';

export const useUserInit = () => {
  const [inited, setInited] = useState(false);
  const preferStore = usePreferenceStore((state) => {
    return {
      init: state.init,
    };
  });
  useAsyncEffect(async () => {
    await preferStore.init();
    setInited(true);
  }, []);
  return inited;
};
